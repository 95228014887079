<template>
  <div>
    <MainLayout>
      <template v-slot:navbar-btn-wrap>
        <DeletePopup
          name="воспитателя"
          :delete-function="deletePersonnel"
          :title="sourceObject ? sourceObject.first_name : ''"
        />
        <router-link
          v-if="urlQuery && urlQuery.id"
          :to="`/personnelEdit/` + urlQuery.id"
          class="btn btn-edit btn_color_grey"
          >редактировать</router-link
        >
      </template>
      <SlideLayout :tabs="slideTabs">
        <template v-slot:groups> <Groups /> </template>
        <template v-slot:info>
          <div class="keep__title">
            <p class="keep__text">Общая информация</p>
          </div>
          <BasicTableWithoutHeader :table-data="getDataRequisites" />
        </template>
      </SlideLayout>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import Groups from "@/components/info/Personnel/Groups";
import DeletePopup from "@/components/popup/DeletePopup";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";
export default {
  name: "PersonnelInfoPage",
  components: {
    DeletePopup,
    Groups,
    BasicTableWithoutHeader,
    SlideLayout,
    MainLayout
  },
  data() {
    return {
      slideTabs: [
        {
          text: "Группы",
          value: "groups",
          icon: null
        },
        {
          text: "Общая информация",
          value: "info",
          icon: "i"
        }
      ]
    };
  },
  computed: {
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getStaffInfo;
    },
    getDataRequisites() {
      return [
        {
          text: "ФИО",
          value: `${this.sourceObject?.last_name} ${
            this.sourceObject?.first_name
          } ${this.sourceObject?.middle_name || ""}`
        },
        {
          text: "Телефон",
          value: phoneFilter.execute(this?.sourceObject?.phone_number)
        },
        {
          text: "Регистрация",
          value:
            dictionariesHelper.accountsStatus.userAuth[
              this.sourceObject?.is_phone_confirmed
            ]
        },
        {
          text: "ID",
          value: this.sourceObject?.id
        }
      ];
    }
  },
  methods: {
    deletePersonnel() {
      this.$store
        .dispatch("clearPersonnel", {
          idChildrenGroup: this.childrenGroupsId,
          teacherId: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.personnel)
          );
        });
    }
  },
  props: {
    childrenGroupsId: {
      type: [String, Number]
    }
  },
  created() {
    this.$store.commit("setSearchQuery", {
      query: this.urlQuery.query,
      id: this.urlQuery.id
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchOrganizationStaffById").finally(() => {
      this.isDateLoaded = false;
    });
  }
};
</script>

<style scoped></style>
